<template>
  <div class="prompt-debug-list">
    <a-form-model ref="form" :model="form" v-bind="layout">
      <a-row>
        <a-col :span="10">
          <a-form-model-item label="发布操作时间">
            <a-space>
              <a-date-picker
                style="width: 100%"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD "
                v-model="form.startTime"
                placeholder="开始时间" />
              ~
              <a-date-picker
                style="width: 100%"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD "
                v-model="form.endTime"
                placeholder="结束时间" />
            </a-space>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="发布账号昵称">
            <a-input v-model="form.authorName" placeholder="请输入" allowClear />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="12" type="flex" justify="end">
        <a-button @click="handleReset">重置</a-button>
        <a-button type="primary" @click="getDyPublishControllerList(true)" style="margin: 0 20px">查询</a-button>
      </a-row>
    </a-form-model>
    <div class="data-table">
      <a-table
        :loading="loading"
        :columns="ReleaseListColumns"
        :data-source="tableData"
        :pagination="false"
        :row-key="(record, index) => index"
      >
        <div slot="operate" slot-scope="record">
          <a-button
            type="link"
            @click="showcontentModal(record)"
          >内容详情
          </a-button>
          <br>

          <a-popconfirm
            v-if="getBtnIsShow(record.publishTime)"
            title="你确定要删除发布计划吗？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="cancelPublishControllerTask(record)"
          >
            <a-button type="link">删除发布计划</a-button>
          </a-popconfirm>
        </div>
        <div slot="status" slot-scope="text,record">
          <span>{{ text || '-' }}</span>
          <a-icon
            v-if="getIconIsShow(record.publishTime)"
            type="redo"
            style="color: #40a9ff;
            padding-left: 6px;
            cursor: pointer"
            @click="refreshVideoStatus(record)" />
        </div>
        <div class="cover-column" slot="coverUrl" slot-scope="text, record" @click="handlePreview(record)">
          <base-img :src="record.coverUrl" alt="成片" referrerpolicy="no-referrer" style="cursor: pointer" />
        </div>
        <div slot="scriptName" slot-scope="text, record" class="blue-text" @click="handlePreview(record)">
          {{ record.criptName || '-' }}
        </div>
      </a-table>
      <base-pagination
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        :options="['100']"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>

    <PreviewModal
      :visible="previewVisible"
      :previewSrc="previewSrc"
      :isAuth="false"
      :isAutoplay="true"
      previewType="video"
      @cancel="
        previewVisible = false;
        previewSrc = '';
      "
    />

    <a-modal
      title="内容详情"
      :visible="contentModal"
      width="55%"
      :footer="false"
      @cancel='contentModal = false'
    >
      <p>发布标题：{{ record_data.text || '-' }}</p>
    </a-modal>
  </div>
</template>

<script>
import { ReleaseListColumns } from '../autoVideoLib/constants';
import moment from 'moment/moment';
import api from '@/api/autoVideoLib.js';
import PreviewModal from '@/components/PreviewModalVideo/PreviewModalVideo';
import BaseImg from '@/components/BaseImg';

export default {
  components: { PreviewModal, BaseImg },
  data() {
    return {
      form: {
        authorName: '',
        startTime: '',
        endTime: '',
      },
      ReleaseListColumns,
      loading: false,
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      },
      tableData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 100,
      },
      record_data: {},
      contentModal: false,
      previewVisible: false,
      previewSrc: '',
    };
  },
  computed: {},
  mounted() {
    this.form.startTime = moment(new Date()).subtract(29, 'days');
    this.form.endTime = moment(new Date());
    this.getDyPublishControllerList(true);
  },
  methods: {
    getDyPublishControllerList(isFirst) {
      const startTime = this.form.startTime ? moment(this.form.startTime).valueOf() : undefined;
      const endTime = this.form.endTime ? moment(this.form.endTime).valueOf() : undefined;
      if (startTime && endTime && startTime > endTime) {
        this.$message.error('创建日期开始时间不能大于结束时间');
        return;
      }
      this.loading = true;
      const params = {
        ...this.form,
        startTime: this.form.startTime ? moment(this.form.startTime).format('YYYY-MM-DD 00:00:00') : undefined,
        endTime: this.form.endTime ? moment(this.form.endTime).format('YYYY-MM-DD 23:59:59') : undefined,
        page: isFirst ? 1 : this.pagination.current,
        size: this.pagination.pageSize,
      };
      api.getDyPublishControllerList(params)
        .then(({ data, code, message }) => {
          if (code === 200) {
            this.tableData = data?.list;
            this.pagination.current = data?.page;
            this.pagination.total = data?.total;
          } else {
            this.$message.error(`${ message }`);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDyPublishControllerList();
    },
    handlePreview(record) {
      this.previewSrc = record.awemeUrl;
      this.previewVisible = true;
    },
    handleReset() {
      Object.assign(this.form, this.$options.data().form);
      this.form.startTime = moment(new Date());
      this.form.endTime = moment(new Date());
      this.getDyPublishControllerList(true);
    },
    showcontentModal(record) {
      this.contentModal = true;
      this.record_data = record || {};
    },
    cancelPublishControllerTask(record) {
      this.loading = true;
      api.cancelPublishControllerTask({ id: record.id }).then(res => {
        if (res.code == 200) {
          this.$message.success('已删除该发布计划');
          this.getDyPublishControllerList(true);
        } else {
          this.$message.error('删除发布计划失败');
        }
        this.loading = false;
      });
    },
    refreshVideoStatus(record) {
      this.loading = true;
      api.refreshVideoStatus({ publishId: record.id }).then(res => {
        if (res.code == 200) {
          this.$message.success('已获取最新数据');
          this.$set(record, 'status', res.data.status);
          this.$set(record, 'failCause', res.data.message);
        } else {
          this.$message.error('获取数据失败');
        }
        this.loading = false;
      });
    },
    getBtnIsShow(_date) {
      if (_date) {
        let targetDate = new Date(_date);
        let currentDate = new Date();
        if (targetDate > currentDate) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getIconIsShow(_date) {
      if (_date) {
        let targetDate = new Date(_date);
        let currentDate = new Date();
        if (targetDate <= currentDate) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>


<style scoped lang="less">
.data-table {
  margin-top: 20px;

  .cover-column {
    width: 60px;
    height: 80px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .blue-text {
    color: #509bf8;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
